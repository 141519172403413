<template>
  <div class="FilterPanelButtons desk:border-b border-light">
    <div
      class="mobOnly:mx-16
      flex justify-between items-center
      cursor-pointer
      type-headline-sm leading-single
      mobOnly:border-b border-light"
      @click="open = !open"
    >
      <div
        class="basis-full py-24 "
        :class="{
          'desk:pb-20': filteredList.length > 10 || search.trim() !== ''
        }"
      >
        {{ globalContent.getFilterName(value.categoryKey) }}
      </div>
      <div v-if="selectedInGroup" class="desk:hidden whitespace-nowrap flex items-center pr-12">
        <button
          class="uppercase underline mr-12"
          @click.stop="$emit('reset')"
        >
          {{ $t('filter.button.clear') }}
        </button>
        <div class="h-20 bg-brandPrimary flex items-center type-xxs-medium px-12">
          {{ selectedInGroup }}
        </div>
      </div>

      <div class="basis-16 shrink-0 relative mobOnly:-top-1">
        <img
          src="/icons/chevron-right.svg"
          class="desk:hidden w-16 h-16"
          alt="Open/Close"
        >
        <img
          src="/icons/chevron-up.svg"
          class="hidden desk:block w-16 h-16"
          alt="Open/Close"
          :class="{
            'rotate-180': !open,
            'rotate-0': open,
          }"
        >
      </div>
    </div>
    <transition :name="uiStore.isMobile ? 'fromRight':''">
      <component
        :is="uiStore.isMobile ? GenericSideMenu : 'div'"
        v-if="open"
        class="limitHeight"
        content-class="mobOnly:pt-18"
        :title="globalContent.getFilterName(value.categoryKey)"
        :show-back="true"
        position="right"
        @back="open = false"
        @close="$emit('close')"
      >
        <div v-if="filteredList.length > 10 || search.trim() !== ''">
          <div class="inlineSearch">
            <input
              v-model="search"
              type="text"
              :placeholder="$t('filter.search.category', { category: globalContent.getFilterName(value.categoryKey).toLowerCase()})"
            >
          </div>
        </div>

        <div
          ref="scrollElement"
          class="desk:max-h-320 overflow-auto scrollableDiv"
        >
          <div
            v-for="option in filteredList"
            :key="option.name"
          >
            <div class="flex justify-between items-start type-sm-medium mb-4">
              <div class="basis-24 shrink-0 mr-8">
                <input
                  :id="value.categoryKey + option.name"
                  type="checkbox"
                  :checked="option.selected"
                  :style="getCheckboxBg(option.name)"
                  :class="{
                    'whiteCheck': getCheckboxIsDark(option.name),
                    'indeterminate': anyChildSelected(option.name),
                  }"
                  @change="updateOption( option.name, $event.target.checked )"
                >
              </div>

              <label :for="value.categoryKey + option.name" class="basis-full break-all">
                {{ globalContent.getFilterOption(`${value.categoryKey}.${option.name}`).name }}
              </label>
              <label :for="value.categoryKey + option.name" class="text-dark type-xs">({{ option.count }})</label>
            </div>
            <div v-for="subOption in getChilds(option.name)" :key="subOption.name">
              <div class="flex justify-between items-start type-sm-medium mb-4">
                <div class="basis-24 shrink-0 mr-8" />
                <div class="basis-24 shrink-0 mr-8">
                  <input
                    :id="value.categoryKey + subOption.name"
                    type="checkbox"
                    :checked="subOption.selected"
                    @change="updateOptionChild(option.name, subOption.name, $event.target.checked)"
                  >
                </div>

                <label :for="value.categoryKey + subOption.name" class="basis-full">
                  {{ globalContent.getFilterOption(`${value.categoryKey}.${subOption.name}`).name }}
                </label>
                <label :for="value.categoryKey + subOption.name" class="text-dark type-xs">({{ subOption.count }})</label>
              </div>
            </div>
          </div>
        </div>
      </component>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { FilterPanelOption } from '~/components/product/FilterPanel.vue';
import { useUiStore } from '~/store/ui';
import { useHasScroll } from '~/composeables/useScrollFix';
import { useGlobalContentStore } from '~/store/globalContent';
import GenericSideMenu from '~/components/menu/GenericSideMenu.vue';
import { StyleValue } from 'vue';
import { isLight } from '~/util/colorUtil';

const props = defineProps<{
  value: FilterPanelOption,
  position: number,
}>();
const uiStore = useUiStore();
const globalContent = useGlobalContentStore();

const scrollElement = ref(null);

useHasScroll(scrollElement);

const open = ref(false);
// First 2 starts open on desktop
if (!uiStore.isMobile && props.position < 2) {
// if (props.position < 2) {
  open.value = true;
}

const emit = defineEmits<{
  (e: 'setFilter', event: { value: string, selected: boolean}[]): void;
  (e: 'reset'): void;
  (e: 'close'): void;
}>();

const search = ref('');

const filteredList = computed(()=> {
  if (search.value.trim() === '' && props.value.categoryKey !== 'categoryFilters') {
    return props.value.options;
  }
  const output = props.value.options.filter((f)=> {
    const name = globalContent.getFilterOption(`${props.value.categoryKey}.${f.name}`).name;
    return f.selected
        || f.name.toLowerCase().startsWith(search.value.toLowerCase()) // <- perhaps this is unnecessary, example brand names are 44, 46 etc
        || name.toLowerCase().startsWith(search.value.toLowerCase())
    ;
  });

  if (props.value.categoryKey !== 'categoryFilters') {
    return output;
  }
  const topLevel = globalContent.filterSettings.filterValues
    .filter((f)=> f.parentCode === null && f.code.startsWith('categoryFilters.'))
    .map((i) => i.code);
  return output.filter((f)=> topLevel.includes('categoryFilters.'+f.code) || f.subAsToplevel );
});

const getChilds = (parent: string) => {
  if (props.value.categoryKey !== 'categoryFilters') {
    return [];
  }
  const code = globalContent.filterSettings.filterValues.find((f) => f.code === 'categoryFilters.'+parent);
  if (!code) {
    return [];
  }
  const childs = globalContent.filterSettings.filterValues.filter((f) => f.parentCode === code.code).map((i) => i.code);
  if (!childs.length) {
    return [];
  }
  return props.value.options.filter((f)=> childs.includes(`${f.categoryKey}.${f.code}`) );
};

const anyChildSelected = (parent: string) => {
  return getChilds(parent).some((s)=> s.selected);
};

const selectedInGroup = computed(()=> {
  return props.value.options.reduce((accumulator, currentValue) => accumulator + (currentValue.selected ? 1:0), 0);
});

const getCheckboxBg = (key: string): StyleValue => {
  if (props.value.categoryKey !== 'parametricFilters.color') {
    return {};
  }
  const hex = globalContent.getFilterOption(`${props.value.categoryKey}.${key}`).description;
  if (!hex) {
    return {};
  }
  return  {
    'background-color': hex,
  };
};

const getCheckboxIsDark = (key: string): boolean => {
  if (props.value.categoryKey !== 'parametricFilters.color') {
    return false;
  }
  const hex = globalContent.getFilterOption(`${props.value.categoryKey}.${key}`).description;
  if (!hex) {
    return false;
  }
  return !isLight(hex);
};

const updateOption = (optionName: string, selected: boolean) => {
  let event = [{ value: optionName, selected }];
  const children = getChilds(optionName);
  children.forEach((f)=> {
    event.push({ value: f.name, selected: false });
  });
  emit('setFilter', event);
};
const updateOptionChild = (parentName: string, optionName: string, selected: boolean) => {
  let event = [
    { value: optionName, selected },
    { value: parentName, selected: false },
  ];
  emit('setFilter', event);
};

</script>

<style scoped lang="postcss">

.inlineSearch {
  @apply relative mb-16;
  input {
    @apply border-b border-light w-full leading-single pb-12 pl-32;

    &::placeholder {
      @apply text-darker;
    }
  }
  &:before {
    @apply block absolute left-0 top-0 w-24 h-24;
    background: url("/icons/search.svg") no-repeat;
    background-size: 20px 20px !important;
    content: '';
  }
}
.limitHeight {
  @screen mobOnly {
    height: calc((var(--vh, 1vh) * 100) - 75px);
  }
}
</style>
